<script setup>
import { Head, Link, useForm, usePage } from "@inertiajs/vue3";
import AuthenticationCard from "@/Components/Jetstream/AuthenticationCard.vue";
import AuthenticationCardLogo from "@/Components/Jetstream/AuthenticationCardLogo.vue";
import VInput from "@/Components/BaseComponents/VInput.vue";
import VButton from "@/Components/BaseComponents/VButton.vue";
import { trans } from "@/vendor/inertia-scaffold/trans.js";

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post(route("login"), {
        onFinish: () => form.reset("password"),
    });
};
</script>

<template>
    <Head title="Log in" />

    <AuthenticationCard>
        <template #logo>
            <AuthenticationCardLogo />
        </template>

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>
        <form @submit.prevent="submit">
            <div>
                <v-input
                    :label="trans('auth.email')"
                    label-classes="w-1/4"
                    input-classes="w-3/4"
                    type="email"
                    :name="'email'"
                    v-model="form.email"
                    id="email"
                    autofocus
                    autocomplete="username"
                    :placeholder="trans('auth.email')"
                >
                </v-input>
            </div>

            <div class="mt-4">
                <v-input
                    :label="trans('auth.password')"
                    label-classes="md:w-1/4 sm:1/3"
                    input-classes="md:w-3/4 sm:2/3"
                    type="password"
                    name="password"
                    v-model="form.password"
                    id="password"
                    required
                    autocomplete="current-password"
                    :placeholder="trans('auth.password')"
                ></v-input>
            </div>
            <div class="mt-6 md:w-3/4 sm:w-2/3 w-1/2 float-right">
                <div class="flex items-baseline justify-between">
                    <v-button type="submit" kind="primary">
                        {{ trans("auth.login") }}
                    </v-button>
                    <v-button
                        :href="route('password.request')"
                        kind="info"
                        class="font-11 !px-0"
                    >
                        {{ trans("auth.password_forgotten") }}
                    </v-button>
                </div>
            </div>
        </form>
        <template #footer>
            <p class="text-white text-[13px] mt-6">
                {{ trans("auth.no_account_yet")
                }}<v-button
                    kind="link"
                    :href="route('register')"
                    class="text-gray underline underline-offset-8 hover:no-underline !px-1 !normal-case !inline-block !shadow-none !text-[13px]"
                >
                    {{ trans("auth.click_to_register") }}</v-button
                >
            </p>
        </template>
    </AuthenticationCard>
</template>
<style scoped lang="scss">
.font-11 {
    font-size: 11px;
}
</style>
